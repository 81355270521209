<template>
<homeContainer class="Hm">
  <featuresContainer class="Ft">
    <b-container class="max">
      <b-row>
        <b-col cols="7" id="21stCentury">
          <kinesis-container event="scroll">
            <kinesis-element :strength="70" axis="x">
              <featureTitle>
                <span class="lightSeaGreen">Become a 21st Century School by partnering with us today.</span>
              </featureTitle>
            </kinesis-element>
            <kinesis-element :strength="70" axis="x">
              <featureSubTitle class="text-muted">
                whiteStrings equips schools of today to navigate through the
                future of learning.
              </featureSubTitle>
            </kinesis-element>
          </kinesis-container>
        </b-col>
        <b-col cols="5">
          <kinesis-container event="scroll">
            <svgContainer>
              <kinesis-element :strength="70" axis="x">
                <img :src="bg21" alt="background" class="oneSvg" />
              </kinesis-element>
              <kinesis-element :strength="50" axis="x" type="depth">
                <img :src="bgBooks21" alt="books" class="oneSvg" />
              </kinesis-element>
              <kinesis-element :strength="50" axis="x" type="depth">
                <img :src="bgLWindow21" alt="background" class="oneSvg" />
              </kinesis-element>
              <kinesis-element :strength="80" axis="x" type="depth">
                <img :src="bgMan21" alt="background" class="oneSvg" />
              </kinesis-element>
              <kinesis-element :strength="50" axis="x" type="depth">
                <img :src="bgMWindow21" alt="background" class="oneSvg" />
              </kinesis-element>
              <kinesis-element :strength="50" axis="x" type="depth">
                <img :src="bgRWindow21" alt="background" class="oneSvg" />
              </kinesis-element>
            </svgContainer>
          </kinesis-container>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="5">
          <kinesis-container event="scroll">
            <svgContainer>
              <kinesis-element :strength="70" axis="x">
                <img :src="bgCom" alt="background" class="oneSvg" />
              </kinesis-element>
              <kinesis-element :strength="90" axis="x" type="depth">
                <img :src="bgComMan" alt="man" class="oneSvg" />
              </kinesis-element>
              <kinesis-element :strength="50" axis="x" type="depth">
                <img :src="bgComWindow" alt="window" class="oneSvg" />
              </kinesis-element>
            </svgContainer>
          </kinesis-container>
        </b-col>
        <b-col cols="7">
          <kinesis-container event="scroll">
            <kinesis-element :strength="-70" axis="x">
              <featureTitle class="right">
                <span class="lightSeaGreen">Communicate</span> stronger and <br />
                <span class="lightSeaGreen">Collaborate</span> wider.
              </featureTitle>
            </kinesis-element>
            <kinesis-element :strength="-70" axis="x">
              <featureSubTitle class="right text-muted">
                21st Century Schools have stronger connections among its leaders, parents, 
                teachers and students. And it openly works with other like-minded learning communities, from local to global.
              </featureSubTitle>
              <!-- <b-button 
                v-scroll-to="'#bookDemo'"
                class="btnTalk lightSeaGreen right"
              >
                Talk to our team now
              </b-button> -->
            </kinesis-element>
          </kinesis-container>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="7">
          <kinesis-container event="scroll">
            <kinesis-element :strength="70" axis="x">
              <featureTitle>
                <span class="lightSeaGreen">Critical Thinking </span> with ease and <br />
                <span class="lightSeaGreen">Creating </span> with more confidence.
              </featureTitle>
            </kinesis-element>
            <kinesis-element :strength="70" axis="x">
              <featureSubTitle class="text-muted">
                21st Century Schools make data-driven decisions to make their operations and enrollments perform better. 
                And it can customize their programs based on the unique needs of their students to enhance classroom experience. 
              </featureSubTitle>
            </kinesis-element>
          </kinesis-container>
        </b-col>
        <b-col cols="5">
          <kinesis-container event="scroll">
            <svgContainer>
              <kinesis-element :strength="70" axis="x">
                <img :src="bgCreate" alt="background" class="oneSvg" />
              </kinesis-element>
              <kinesis-element :strength="50" axis="x" type="depth">
                <img :src="bgCreateBlocks" alt="books" class="oneSvg" />
              </kinesis-element>
              <kinesis-element :strength="50" axis="x" type="depth">
                <img
                  :src="bgCreateBuildings"
                  alt="background"
                  class="oneSvg"
                />
              </kinesis-element>
              <kinesis-element :strength="50" axis="x" type="depth">
                <img :src="bgCreateClounds" alt="background" class="oneSvg" />
              </kinesis-element>
              <kinesis-element :strength="90" axis="x" type="depth">
                <img :src="bgCreateLGirl" alt="background" class="oneSvg" />
              </kinesis-element>
              <kinesis-element :strength="50" axis="x" type="depth">
                <img :src="bgCreatePhone" alt="background" class="oneSvg" />
              </kinesis-element>
              <kinesis-element :strength="90" axis="x" type="depth">
                <img :src="bgCreateRGirl" alt="background" class="oneSvg" />
              </kinesis-element>
              <kinesis-element :strength="50" axis="x" type="depth">
                <img :src="bgCreateShadow" alt="background" class="oneSvg" />
              </kinesis-element>
            </svgContainer>
          </kinesis-container>
        </b-col>
      </b-row>
    </b-container>      
  </featuresContainer>
  <bookADemoContainer class="Badc" id="bookDemo">
    <b-container class="max">
      <b-row>
        <b-col>
          <div class="leftCol">
            <leftTitle>
              <span class="text-muted">Experience a </span>
              <span class="lightSeaGreen">new generation</span>
              <span class="text-muted">school platform built for the </span>
              <span class="lightSeaGreen">new economy.</span>
            </leftTitle>          
          </div>
        </b-col>
        <b-col class="rightCont">
          <div class="rightCol">
            <h5>
              Let us know what you need and we’ll be happy to <br  class="nn"/>
              provide you the perfect platform for your school.
            </h5>
            <b-form>
              <div class="formGroup" v-for="type in types" :key="type.id">
                <label :for="`${type.typeN}`" v-html="type.name" />
                <b-form-input
                  :id="`${type.typeN}`"
                  :type="type.typeN"
                ></b-form-input>
              </div>
            </b-form>
            <p>
              I consent to receive communications from whiteStrings containing
              news, updates and promotions about whiteStrings and its products
              and services. I understand that I can withdraw my consent at
              anytime.
            </p>

            <b-form-checkbox
              id="checkbox-1"
              v-model="status"
              name="checkbox-1"
              value="accepted"
              unchecked-value="not_accepted"
            >
              I agree to receive email and mobile communications from
              whiteStrings.*
            </b-form-checkbox>

            <p>
              By clicking submit below, you consent to allow whiteStrings to
              store and process the personal information submitted above to
              provide you the content requested.
            </p>

            <b-button class="btnBookADemo">Book a Demo</b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </bookADemoContainer>
</homeContainer>
</template>
<script>
import styled from "vue-styled-components";
import { KinesisContainer, KinesisElement } from "vue-kinesis";

const bookADemoContainer = styled.div`
  min-height: 55vw;
  margin-top:20vw;
  padding: 5vw 0 5vw 0;
  @media (max-width:900px) {
    margin-top:18vh;
  }
`;

// rgba(18, 113, 111, 1) 0%,
// rgba(0, 255, 247, 1) 100%
const leftTitle = styled.h1`
  font-family: "SF Pro Display Regular", sans-serif;
  font-weight: 600;
  color: #000000;
  font-size: 2.5vw;
  text-align: left;
  margin-top: 3vw;
  margin-bottom: 1vw;
  position: relative;
  z-index: 1;
  @media (max-width:900px) {
    font-size: 34px;
    text-align: center;
    width: 100%;
    margin-bottom:3vh;
  }
`;

const homeContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top:10vw;
`;

const featureTitle = styled.h1`
  font-family: "SF Pro Display Regular", sans-serif;
  font-weight: 600;
  font-size: 2.6vw;
  -webkit-font-smoothing: antialiased;
  margin-top: 13vw;
  @media (max-width: 700px) {
    font-size: 22px;
    text-align: center !important;
  }
`;

const featureSubTitle = styled.p`
  font-family: "SF Pro Display Regular", sans-serif;
  font-weight: 400;
  font-size: 1.8vw;
  @media (max-width: 700px) {
    font-size: 16px;
    text-align: center !important;
  }
`;

const svgContainer = styled.div`
  position: relative;
  height: 35vw;
  width: 35vw;
  @media (max-width: 700px) {
    height: 50vw;
    width: 50vw;
    margin: 50px auto;
  }
`;

export default {
  name: "BookADemo",
  components: {
    bookADemoContainer,
    leftTitle,

    homeContainer,
    featureTitle,
    featureSubTitle,
    svgContainer,
    
    KinesisContainer,
    KinesisElement,
  },
  data() {
    return {
      types: [
        {
          id: 0,
          typeN: "firstname",
          name: "First Name <span>*</span>",
        },
        {
          id: 1,
          typeN: "firstname",
          name: "Last Name <span>*</span>",
        },
        {
          id: 2,
          typeN: "businessname",
          name: "Business Name <span>*</span>",
        },
        {
          id: 3,
          typeN: "mobilenumber",
          name: "Mobile Number <span>*</span>",
        },
        {
          id: 4,
          typeN: "country",
          name: "Country <span>*</span>",
        },
        {
          id: 5,
          typeN: "typeofschool",
          name: "Which type of school are you?",
        },
      ],
      bg21: require("../assets/images/home/21/only-bg.svg"),
      bgBooks21: require("../assets/images/home/21/only-books.svg"),
      bgLWindow21: require("../assets/images/home/21/only-leftwindow.svg"),
      bgMan21: require("../assets/images/home/21/only-man.svg"),
      bgMWindow21: require("../assets/images/home/21/only-midwindow.svg"),
      bgRWindow21: require("../assets/images/home/21/only-rightwindow.svg"),

      bgCom: require("../assets/images/home/communicate/only-bg.svg"),
      bgComMan: require("../assets/images/home/communicate/only-man.svg"),
      bgComWindow: require("../assets/images/home/communicate/only-window.svg"),

      bgCollab: require("../assets/images/home/collab/only-background.svg"),
      bgCollabDevice: require("../assets/images/home/collab/only-devices.svg"),
      bgCollabpeople: require("../assets/images/home/collab/only-people.svg"),
      bgCollabShadow: require("../assets/images/home/collab/only-shadow.svg"),

      bgCreate: require("../assets/images/home/create/only-background.svg"),
      bgCreateBlocks: require("../assets/images/home/create/only-bg_blocks.svg"),
      bgCreateBuildings: require("../assets/images/home/create/only-buildings.svg"),
      bgCreateClounds: require("../assets/images/home/create/only-clouds.svg"),
      bgCreateLGirl: require("../assets/images/home/create/only-leftgirl.svg"),
      bgCreatePhone: require("../assets/images/home/create/only-phone.svg"),
      bgCreateRGirl: require("../assets/images/home/create/only-rightgirl.svg"),
      bgCreateShadow: require("../assets/images/home/create/only-shadow.svg"),

      bgCrit: require("../assets/images/home/critical/only-bg.svg"),
      bgCritMan: require("../assets/images/home/critical/only-man.svg"),
      bgCritMonitor: require("../assets/images/home/critical/only-monitor.svg"),
      bgCritShadow: require("../assets/images/home/critical/only-shadow.svg"),
      bgCritWindows: require("../assets/images/home/critical/only-windows.svg"),
    };
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/bookademo.scss";
</style>

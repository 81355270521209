<template>
  <aboutContainer class="Abt">
    <headerContainer>
      <b-container>
        <b-row>
          <b-col>
            <Title class="lightSeaGreen" data-aos="zoom-in">
              Building solutions with you in mind.
            </Title>
            <img :src="wtLogo" alt="whiteStrings Logo" class="wtLogo" />
            <subTitle
              data-aos="zoom-in"
              data-aos-duration="1500"
              class="subtitle"
              >whiteStrings was built by passionate individuals to help <br />
              create a new generation of learners by reimagining
              education.</subTitle
            >
          </b-col>
        </b-row>
      </b-container>
    </headerContainer>
    <howToHelp>
      <b-container class="maxHelp" ref="div1">
        <b-row>
          <b-col>
            <Title4 class="lightSeaGreen" data-aos="zoom-in">
              Helping you do so much more than connect the dots.
            </Title4>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <descHelp class="descHelpLeft text-muted" data-aos="zoom-in-right">
              <b class="black">goodStrings</b>
              assists educational institutions in modernizing operations,
              transforming teaching and learning, and enhancing community-wide
              engagement across a hyperconnected digital landscape.
              <br /><br />

              Helping to
              <b class="black">streamline processes</b> and
              <b class="black">free up resources</b>, our
              <b class="black">integrated software solutions</b>
              enable schools to focus on what’s important:
              <b class="black">delivering top-tier learning experiences</b>
              that meet the complex and evolving needs of students, parents,
              staff, and faculty.
              <br /><br />

              At <b class="black">goodStrings</b>, we measure our impact not
              just by what we can do for our partners, but on what they can then
              do for themselves and others. After all, as our name suggests,
              doing ‘good’ is an important part of why we’re in business.
            </descHelp>
          </b-col>
          <b-col cols="6" class="right">
            <img
              :src="help"
              alt="background"
              class="imgColRight"
              data-aos="zoom-in-left"
              data-aos-duration="1500"
            />
          </b-col>
        </b-row>
      </b-container>
    </howToHelp>
    <descAbout class="descAboutSection">
      <b-container class="max">
        <b-row>
          <b-col class="bcolCont">
            <img
              :src="whiteBoardImg"
              alt="white board img"
              class="whiteboardImg"
            />
            <gTitle data-aos="zoom-in" class="gTitle"
              >At whiteStrings, we measure our impact not just by what we can do
              for <br />
              our partners, but on what they can then do for themselves and
              others. <br />
              After all, as our name suggests, doing ‘good’ is an important part
              of <br />
              why we’re in business.
            </gTitle>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <Title4 class="lightSeaGreen" data-aos="zoom-in">
              Building a better future together.
            </Title4>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="maxHelp">
        <b-row>
          <b-col cols="7">
            <descHelpBot
              data-aos="zoom-in-right"
              data-aos-duration="1500"
              class="text-muted"
            >
              We believe that education is the most powerful instrument we
              <br class="nn" />
              have to reduce poverty and inequality – helping to lift up entire
              <br class="nn" />
              communities, laying the foundation for sustained economic
              <br class="nn" />
              growth, and enabling a host of other social benefits to arise. But
              <br class="nn" />
              most importantly, education has the power to change lives. <br />
              <br />

              That’s why we’re committing
              <span class="lightSeaGreen">20%</span> of our profits towards the
              <br class="nn" />
              construction of schools in under-resourced communities across
              <br class="nn" />
              the country. Because increasing access to education is the
              <br class="nn" />
              greatest investment we can make to help society address the
              <br class="nn" />
              most pressing challenges of our time. When you choose to work
              <br class="nn" />
              with us, you will be joining a special group of benefactors
              <br class="nn" />
              supporting the work of the whiteStrings Foundation.
            </descHelpBot>
          </b-col>
          <b-col cols="5">
            <img
              :src="descAboutImg"
              alt="background"
              class="imgCol"
              data-aos="zoom-in-left"
              data-aos-duration="1500"
            />
          </b-col>
        </b-row>
      </b-container>
    </descAbout>
    <beta-schools />
  </aboutContainer>
</template>
<script>
import styled from "vue-styled-components";
import { VuePrlxDirective } from "vue-prlx";
import BetaSchools from "../components/BetaSchools.vue";

const aboutContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const headerContainer = styled.div`
  height: 55vw;
  width: 100%;
  padding-top: 10vw;
  @media (max-width: 700px) {
    padding-top: 15vh;
    height: 70vh;
  }
`;

const Title = styled.h1`
  font-family: "SF Pro Display Regular", sans-serif;
  font-weight: 600;
  color: #000000;
  font-size: 4vw;
  text-align: center;
  margin-top: 5vw;
  margin-bottom: 3vw;
  position: relative;
  z-index: 1;
  @media (max-width: 700px) {
    font-size: 34px;
  }
`;

const Title4 = styled.h1`
  font-family: "SF Pro Display Regular", sans-serif;
  font-weight: 600;
  color: #000000;
  font-size: 3vw;
  text-align: center;
  margin-top: 8vw;
  margin-bottom: 8vw;
  position: relative;
  z-index: 1;
  @media (max-width: 700px) {
    font-size: 34px;
    margin-bottom: 6vh;
    margin-top: 5vh;
  }
`;

const subTitle = styled.h3`
  font-family: "SF Pro Display Regular", sans-serif;
  font-weight: 500;
  color: #000000;
  font-size: 2vw;
  line-height: 1.6;
  text-align: center;
  position: relative;
  z-index: 1;
  margin-top: 3vw;
  @media (max-width: 700px) {
    font-size: 18px;
  }
`;

const howToHelp = styled.div`
  min-height: 50vw;
  width: 100%;
  padding: 5vw 0;
  @media (max-width: 700px) {
    min-height: 50vh;
    padding: 5vh 0;
  }
`;

const gTitle = styled.h3`
  font-family: "SF Pro Display Regular", sans-serif;
  font-weight: 600;
  color: #000000;
  font-size: 2.2vw;
  text-align: center;
  margin-top: 10vw;
  margin-bottom: 10vw;
  position: relative;
  z-index: 1;
  background: -webkit-linear-gradient(
    90deg,
    rgba(33, 199, 30, 255) 0%,
    rgba(101, 235, 101, 255) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.4;
  @media (max-width: 700px) {
    font-size: 24px !important;
    text-align: center;
  }
`;

const descHelp = styled.p`
  font-family: "SF Pro Display Regular", sans-serif;
  font-weight: 400;
  color: #000000;
  font-size: 1.4vw;
  line-height: 1.6;
  text-align: left;
  position: relative;
  z-index: 1;
  margin-top: 2vw;
  @media (max-width: 700px) {
    font-size: 16px !important;
    text-align: center;
  }
`;

const descHelpBot = styled.p`
  font-family: "SF Pro Display Regular", sans-serif;
  font-weight: 400;
  color: #000000;
  font-size: 1.4vw;
  line-height: 1.6;
  text-align: left;
  position: relative;
  z-index: 1;
  margin-top: 4vw;
  margin-left: 10vw;
  @media (max-width: 700px) {
    width: 80%;
    font-size: 16px;
    text-align: center;
    margin: 0 auto 10vh auto;
    padding: 0;
  }
`;

const descAbout = styled.div`
  min-height: 50vw;
  width: 100%;
  padding: 5vw 0;
`;

export default {
  name: "About",
  components: {
    aboutContainer,
    headerContainer,
    Title,
    subTitle,
    Title4,

    howToHelp,
    gTitle,
    descHelp,
    descHelpBot,

    descAbout,
    BetaSchools,
  },
  data() {
    return {
      wtLogo: require("../assets/images/logo/ws_logo.png"),

      help: require("../assets/images/about/asia-businesswoman-using-laptop-talk-colleagues-about-plan-video-call-while-smart-working-from-home-living-room.jpg"),
      help1: require("../assets/images/about/preteen-boy-uses-laptop-make-online-classes-saying-hello-teacher.jpg"),

      descAboutImg: require("../assets/images/about/close-up-hand-holding-house-model-against-textured-background.jpg"),

      whiteBoardImg: require("../assets/images/about/airfocus-K_VeavYEfdA-unsplash.png"),
    };
  },
  directives: {
    prlx: VuePrlxDirective,
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/about.scss";
</style>

<template>
  <betaSchools class="beta">
    <b-container class="max">
      <b-row>
        <b-col>
          <betaTitle class="title" data-aos="zoom-in"
            >Be part of our Beta-School <br />
            partnership program</betaTitle
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="7">
          <betaDesc data-aos="zoom-in-right" data-aos-duration="1500" class="desc text-muted">
            Our experts are with you each step of the <br />
            way to help with your school goals <br />
            and increase your success.
            <b-link
              class="btnTalk lightSeaGreen"
              data-aos="zoom-in-right"
              data-aos-duration="1500"
              to="/book-a-demo"
              >Talk to our team now</b-link
            >
          </betaDesc>
        </b-col>
        <b-col cols="5">
          <kinesis-container event="scroll">
            <svgContainer>
              <kinesis-element :strength="61" axis="y" type="depth">
                <img :src="beta1" alt="background" class="oneSvg" />
              </kinesis-element>
              <kinesis-element :strength="60" axis="y" type="depth">
                <img :src="beta2" alt="books" class="oneSvg" />
              </kinesis-element>
              <kinesis-element :strength="62" axis="y" type="depth">
                <img :src="beta3" alt="background" class="oneSvg" />
              </kinesis-element>
              <kinesis-element :strength="63" axis="y" type="depth">
                <img :src="beta4" alt="background" class="oneSvg" />
              </kinesis-element>
              <kinesis-element :strength="60" axis="y" type="depth">
                <img :src="beta5" alt="background" class="oneSvg" />
              </kinesis-element>
            </svgContainer>
          </kinesis-container>
        </b-col>
      </b-row>
    </b-container>
  </betaSchools>
</template>
<script>
import styled from "vue-styled-components";
import { KinesisContainer, KinesisElement } from "vue-kinesis";

// Beta Schools
const betaSchools = styled.div`
  width: 100%;
  min-height: 50vw;
`;

// rgba(18, 113, 111, 1) 0%,
// rgba(0, 255, 247, 1) 100%
const betaTitle = styled.h1`
  font-family: "SF Pro Display Regular", sans-serif;
  font-weight: 600;
  color: #000000;
  font-size: 4vw;
  text-align: center;
  margin-top: 10vw;
  margin-bottom: 5vw;
  position: relative;
  z-index: 1;
  background: -webkit-linear-gradient(
    80deg,    
    rgba(33,199,30,255) 0%,
    rgba(101,235,101,255) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 700px) {
    font-size: 34px;
    transition: all 0.2s ease-in-out !important;
  }
`;

const betaDesc = styled.p`
  font-family: "SF Pro Display Regular", sans-serif;
  font-weight: 400;
  font-size: 2vw;
  height: 30vw;
  margin-top: 10vw;
  @media (max-width: 700px) {
    font-size: 22px;
    transition: all 0.2s ease-in-out !important;
    text-align: center !important;
  }
`;

const svgContainer = styled.div`
  position: relative;
  height: 35vw;
  width: 35vw;
  @media (max-width: 700px) {
    height: 80vw;
    width: 100%;
    margin: 50px auto !important;
  }
`;
export default {
  name: "BetaSchools",
  components: {
    betaSchools,
    betaTitle,
    betaDesc,
    svgContainer,
    KinesisContainer,
    KinesisElement,
  },
  data() {
    return {
      beta1: require("../assets/images/home/beta-students/left-boy1.svg"),
      beta2: require("../assets/images/home/beta-students/left-girl1.svg"),
      beta3: require("../assets/images/home/beta-students/middle-boy1.svg"),
      beta4: require("../assets/images/home/beta-students/right-boy1.svg"),
      beta5: require("../assets/images/home/beta-students/right-girl1.svg"),
    };
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/beta.scss";
</style>